import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '@/stores/AuthStore';
import axios from 'axios'

const routes = [
  {
    path: '/:id',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, 
  mode: 'history'
});

// Navigation guard to check for authentication on protected routes
router.beforeEach(async (to, from) => {
 const authStore = useAuthStore();
  console.log(from.fullPath);
  const urlParts = to.fullPath.split('/');
  const id = urlParts[urlParts.length - 1];
  console.log(id);
  try {
    const response = await axios.get(`https://lora.ivu-software.de/api/login/ismaasunlocked?hash=${id}`);

    // Check if the response status is 200
    if (response.status === 200) {
  //    console.log("great");
      authStore.login(id);
      // If status is 200, allow access to the route
      return;   
    } else {
      // If status is not 200, deny access to the route
      return false;
    }
  } catch (error) {
    // Handle errors
    console.error('Error:', error);
    return false;
  }
  
});

export default router
