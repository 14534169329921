import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'

//vuetify imports
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

//proxy middleware imports

const myCustomTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#c3c3c3',
    primary: '#a9c436',
    'primary-darken-1': '#3700B3',
    secondary: '#232f5d',
    'secondary-darken-1': '#018786',
    error: '#b3172c',
    info: '#bcc9db',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme,
    }
  },
  icons: {
    defaultSet: 'mdi', 
  },
})



const pinia = createPinia()

const app = createApp(App).use(router)

app.use(vuetify)
app.use(pinia)

app.mount('#app')
