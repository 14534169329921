
<template>
      <v-main>
        <NotesComponent></NotesComponent>
      </v-main>
</template>

<script>
// @ is an alias to /src
/*import comments from '@/components/comments.vue'*/
import NotesComponent from '@/components/NotesComponent.vue'


export default {
  name: 'HomeView',
  components: {
    NotesComponent
  }
}
</script>
