<script>
import {useNoteStore} from "@/stores/NoteStore"
import {useAuthStore} from "@/stores/AuthStore"
import CommentsComponent from '@/components/CommentsComponent.vue'
import {provide, onBeforeMount, ref} from 'vue'
// Import only the required Vuetify components from the vuetify.js plugin
import dayjs from "dayjs";
/*import { storeToRefs } from "pinia";*/

export default {
  name: 'NotesComponent',
    data () {
      return {}
    },
    components: {
      CommentsComponent
    },

    setup(){
      //NoteStore
      const NoteStore = useNoteStore();
      const authStore = useAuthStore();

      //Subscription to NoteStore
      const unsubscribe = NoteStore.$onAction(
        ({
          name, // name of the action
          args, // array of parameters passed to the action
          after, // hook after the action returns or resolves
          onError, // hook if the action throws or rejects
        }) => {
              const startTime = Date.now();
              // this will trigger before an action on `store` is executed
              console.log(`Start "${name}" with params [${args.join(', ')}].`);

              // this will trigger if the action succeeds and after it has fully run.
              // it waits for any returned promised
              after((result) => {
                console.log(
                  `Finished "${name}" after ${
                    Date.now() - startTime
                  }ms.\nResult: ${result}.`
                );
      })

      // this will trigger if the action throws or returns a promise that rejects
      onError((error) => {
        console.warn(
          `Failed "${name}" after ${Date.now() - startTime}ms.\nError: ${error}.`
        )
      })
      }
      )
      unsubscribe();
      //Providing the one instance of NoteSTore created above to Child Components
      provide('NoteStore', NoteStore);
      //fetching all Notes from API in using the onBeforeMount hoook
      onBeforeMount(() => {
        NoteStore.fetchNotes();
      })

      //reactive Variables
      const newContent = ref('');
      const newTitle = ref('');
      const NoteCreated = ref(false);
      const NoteNotCreated = ref(false);
      const LikeNotCreated = ref(false);
      //Methods
      const formatDate = (dateString) => {
        const date = dayjs(dateString);
        return date.format('DD.MM.YYYY');
      }

      const submit = async () => {
        NoteCreated.value = await NoteStore.createNote(newContent.value, newTitle.value);
        if(!NoteCreated.value){
          NoteNotCreated.value = true;
        }
        else{
          newContent.value = '';
          newTitle.value = '';
        }

      }

      const createLike = async (note_ID) => {
        const success = await NoteStore.createLike(authStore.userID, note_ID);
        if(!success){
          LikeNotCreated.value = true
        }
      }

      return {newContent, newTitle, NoteCreated, NoteNotCreated, LikeNotCreated, createLike, submit, formatDate, NoteStore};

    }
  }
</script>

<template>
<v-card
class="mx-auto pa-4 ma-5"
max-width="80%"
variant="outlined"
>
  <v-card-item>
    <v-container>
      <v-form ref="form" @submit.prevent="submit(NoteStore)">
      <v-row>
        <div class="text-md-left">
              <div class="text-h5 mb-1">
                Geben Sie hier einen Wunsch für ein Feature im MaaS ein!
              </div>
          </div>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-text-field
          v-model="newTitle"
          label="Titel"
          variant="outlined"
          row-height="25"
          shaped
          required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-textarea
          auto-grow
          variant="outlined"
          rows="3"
          row-height="25"
          shaped
          label = "Wunsch hinzufügen"
          v-model="newContent"
          required
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ps-4">
        <v-btn
        color="primary"
        type="submit"
      >
        Hinzufügen
        <v-icon
          end
          icon="mdi-checkbox-marked-circle"
        ></v-icon>
        </v-btn>
      </v-row>
    </v-form>
    </v-container>
  </v-card-item>
  <v-alert
  v-model = "NoteCreated"
  closable
  class="rounded-10">
    Wunsch wurde hinzugefügt.
  </v-alert>
  <v-alert
  v-model = "NoteNotCreated"
  closable
  class="rounded-10"
  color="error">
    Wunsch konnte nicht hinzugefügt werden.
  </v-alert>
</v-card>



<v-card v-for="note in NoteStore.notes.data" :key="note.note_ID" class="mx-auto pa-4 ma-3" max-width="80%" variant="outlined" >
  <v-card-item>
    <v-container>
      <v-row>
        <v-col md="7">
            <div class="text-md-left">
              <div class="text-h6 mb-1">
              {{ note.note_title }}
              </div>
              <div>{{ note.content }}</div>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="2" class="text-center">
            <v-btn
                  @click=" createLike(note.note_ID)"
                  class="ma-2"
                  variant="text"
                  icon="mdi-thumb-up"
                  color="primary"
                ></v-btn>
                <v-chip color="primary" size="x-large">
                  {{ note.likes }}
                </v-chip>
        </v-col>
      </v-row>
      <v-row md="12">
        <div class="text-right">
          <v-chip
          class="ma-2"
          >
          {{formatDate(note.created) }}
        </v-chip>
        </div>
      </v-row>
      <v-row>
        <v-card
        width = "100%"
        color="background">
        <v-divider></v-divider>
      <CommentsComponent :note_ID=note.note_ID></CommentsComponent>
        </v-card>
      </v-row>
    </v-container>
  </v-card-item>
</v-card>

<div class="text-center">
    <v-dialog
      v-model="LikeNotCreated"
      width="auto"
    >
      <v-card>
        <v-card-text>
          Sie haben den Wunsch bereits geliket. Pro Benutzer kann nur ein Like pro Wunsch abgegeben werden.
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" block @click="LikeNotCreated = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>