<script>

// Import only the required Vuetify components from the vuetify.js plugin
import {inject, ref, computed} from 'vue'

export default {
  name: 'CommentsComponent',
    data () {
      return {
      }
    }, 
    props: {
    note_ID: {
      type: Number,
      required: true
    }
  },
  setup(props) {

    const store = inject('NoteStore');
    
    const newComment = ref("");
    const commentCreated = ref(false);
    const commentNotCreated = ref(false);
    const comments = computed(() => store.getCommentsByNote(props.note_ID));

    const submitComment = async (id) => {
      const success = store.createComment(id, newComment.value);
      if (success){
        newComment.value = "";
        commentCreated.value = true;
      }
      else{
        commentNotCreated.value = true;
        alert("Not working");
      }
    };

    const fetchComments = async()=> {await store.fetchComments(props.note_ID)};

    return { newComment, submitComment, commentCreated, commentNotCreated, comments, fetchComments, store};
  }
}
</script>

<template>
<!--Add Comment Section-->
<v-card-item density="compact">
  <v-container>
    <v-form ref="CommentForm" @submit.prevent="submitComment(this.note_ID)">
      <v-row>
        <v-col cols="10">
          <v-sheet color="background">
              <v-text-field
              v-model="newComment"
              label="Kommentar hinzufügen"
              bg-color="background"
              density="compact"
              variant="solo"
              ></v-text-field> 
          </v-sheet>
          </v-col>
          <v-col>
            <v-card-actions>
              <v-btn variant="elevated"
                type="submit" 
                density="compact">
              Hinzufügen
              </v-btn>
            </v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</v-card-item>

<v-alert
  v-model = "commentCreated"
  closable
  class="rounded-10">
    Kommentar wurde hinzugefügt.
  </v-alert>
  <v-alert
  v-model = "commentNotCreated"
  closable
  class="rounded-10"
  color="error">
    Kommentar konnte nicht hinzugefügt werden.
  </v-alert>

<!--Show Comments Section-->
<v-card-item density="compact" @click="fetchComments">
  <v-expansion-panels color="#e4e4e4">
    <v-expansion-panel
      title="Kommentare anzeigen"
      class="rounded-lg"
    >
      <v-expansion-panel-text v-if="store.getCommentsByNote(this.note_ID)">
        <div v-for="comment in comments" :key="comment.commment_ID">
          <v-divider></v-divider>
          <v-container>
            <v-row no-gutters>
              <v-col >
                <v-sheet class = "text-left">{{ comment.comments }}</v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card-item>
</template>