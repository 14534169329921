// authStore.js
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('AuthStore', {
  state: () => ({
    userID: null
  }),
  actions: {
    login(id) {
      this.userID = id;
    },
    logout() {
      this.userID = null;
    }
  },
})
