<template>
  <v-app>
    <v-app-bar class="text-md-left bg-grey-lighten-1" height="112">
      <img class="pa-2" src="/api/logo" alt="MaaS Logo" style="max-height: 100%;"/>
      <v-toolbar-title color="secondary">Feature Wünsche</v-toolbar-title>
    </v-app-bar>
    <router-view/>
    <v-footer>
    <v-row justify="center" no-gutters>
      <v-btn
        href="https://www.meterpan.de/"
        color="secondary"
        variant="text"
        class="mx-2"
        rounded="xl"
      >
        Unsere Website
      </v-btn>
      <v-btn
        href="https://support.meterpan.de/infocenter/"
        color="secondary"
        variant="text"
        class="mx-2"
        rounded="xl"
      >
        Kontakt und Support
      </v-btn>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong color="secondary">MaaS Feature Wünsche</strong>
      </v-col>
    </v-row>
  </v-footer>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7995bd;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #7995bd;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
