import {defineStore} from'pinia'
import axios from 'axios'

// Create an instance of axios with a custom base URL
const api = axios.create({
    baseURL: '/api',   // Use the container name of the Node.js server
    headers: {
        "Content-Type":"application/json", 
        "Accept":"*/*", 
    }
  });

// Add an Axios response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response error:', error.response.status, error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request error:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message);
      }
      return Promise.reject(error);
    }
  );  

export const useNoteStore = defineStore("NoteStore",{
    state: () =>({
            notes: []
    }),
    getters: {
        getNotesById: (state) => (id) => {return state.notes.data.findIndex((notes) => notes.note_ID === id)}, 
        getCommentsByNote: state => id => {
            const index = state.notes.data.findIndex((notes) => notes.note_ID === id)
            const data = state.notes.data[index]?.["comments"]
            return  data ? data : false
          }
        
      },
    actions: {
        //Note Actions
        async fetchNotes(){
        try{
            const data = await api.get('/notes');
            this.notes = data;
        }
        catch(error){
            console.log(error);
        }
        },  
        async createNote(note, newTitle){
            try{
                //create Request 
                const body = JSON.stringify({content: note, 
                title: newTitle});
                const url = '/notes';
                //make Request and await response
                const response = await api.post(url, body);

                //handle response
                this.notes.data.push(response.data[0]);
                return true

            }
            catch(error){
                console.warn(error);
                return false
            }
        }, 
        //Like Actions
        async createLike(user, nID){
            console.log(user);
            try{
                //create Request 
                const body = JSON.stringify({user_ID: user});
                const url = '/likes/' + nID;

                //make Request and await response
                const response = await api.post(url, body);

                //handle response
                if(response){
                    const index = this.getNotesById(nID);
                    this.notes.data[index]["likes"] = response["data"][0]["likes"]
                    return true
                }
                else{
                    return false
                }
            }
            catch(error){
                console.log(error);
            }
        },
        //CommentsActions
        async fetchComments(note_ID){
            const index = this.getNotesById(note_ID);
            if(!this.notes.data[index]["comments"]){
            try{
                const url = '/comments/' + note_ID;
                const data = await api.get(url);
                this.notes.data[index]["comments"] = data.data;
            }
            catch(error){
                console.log(error);
            }
            }
        },
        async createComment(note_ID, content){
            try{
                //create Request 
                const body = JSON.stringify({comment: content});
                const url = '/comments/' + note_ID;

                //make Request and await response
                const response = await api.post(url, body);

                //handle response
                const index = this.getNotesById(note_ID);
                console.log(index);
                console.log(this.notes.data[index]["comments"]);
                if(this.notes.data[index]["comments"]){
                this.notes.data[index]["comments"].push(response.data[0]);
                }else{
                    this.notes.data[index]["comments"] = response.data[0]
                }
                return true

            }
            catch(error){
                console.warn(error);
                return false
            }
        },

    }
});